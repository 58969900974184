@import 'bootstrap-custom';
@import '../node_modules/font-awesome/scss/font-awesome';

$footer-height: 60px;

@font-face {
    font-family: DukeFill;
    src: url(../fonts/duke_fill.otf);
}

@font-face {
    font-family: GraduateRegular;
    src: url(../fonts/graduate_regular.ttf);
}

@font-face {
    font-family: DirtyHeadline;
    src: url(../fonts/dirty_headline.ttf);
}

.font-dirtyheadline {
    font-family: DirtyHeadline;
}

.font-graduateregular {
    font-family: GraduateRegular;
}

.font-dukefill {
    font-family: DukeFill;
}

h1 {
  font-family: DirtyHeadline;
  letter-spacing: 0;
}

h2 {
  font-family: GraduateRegular;
  font-weight: bold;
}

h3, h4, h5, h6 {
  font-family: DukeFill;
  letter-spacing: 1.5px;
}

.home-page-block {
  margin-bottom: 10px !important;

  .homepage-club-name {
    margin: 10px auto;
    font-size: 35px;
    font-weight: normal;
  }
}

.btn {
  font-size: 30px;
  padding: 0 12px;
  font-weight: normal;
  font-family: DukeFill;
  letter-spacing: 3px;
}

.club-action {
  font-family: GraduateRegular;
}


body {
    min-height: 100vh;
    position: relative;
}

#content {
  max-width: 1920px;
  margin: 0 auto;
  padding-bottom: $footer-height + 40;

  .container-fluid {
    >:not(#image-carousel) {
      margin-top: 30px;

      @media (min-width: $screen-sm-min) {
        padding-left: 5%;
        padding-right: 5%;
      }
    }

    #image-carousel + * {
      margin-top: 0;
    }
  }
}

footer {
  $footer-background-color: #ebebeb;

  bottom: 0;
  height: $footer-height;
  position: absolute;
  width: 100%;

  a {
    color: #000;
    text-decoration: underline;

    &:hover {
      text-decoration: underline;
    }
  }

  .navbar {
    align-items: center;
    background-color: $footer-background-color;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    display: flex;
    justify-content: space-between;
    margin: 0;
    width: 100%;

    .footer-menu-items {
      background-color: $footer-background-color;
      line-height: $footer-height;
      position: absolute;
      width: 100%;

      .footer-copyright {
        right: 0;
      }
    }

    .footer-social {
      display: inline-block;
      float: none;
      font-size: 40px;
    }

    ul.navbar-nav {
      text-align: center;
      width: 100%;

      a#copyright {
        text-decoration: none;

        &:hover {
          color: inherit;
        }
      }

      li a {
        line-height: 40px;
        padding: 10px;

        p {
          margin: 0;
        }
      }
    }
  }
}

#clubs-navbar {
  display: flex;

  li {
	  border-left: 1px solid #fff;
	  border-right: 1px solid #fff;
	  z-index: 999;
	  box-shadow: inset 0 -5px 5px rgba(0, 0, 0, 0.25);

	  background-color: currentColor;

	  &:first-of-type {
		  border-left: none;
	  }

	  &:last-of-type {
		  border-right: none;
	  }

	  &.active {
		  border-bottom-color: currentColor;
		  box-shadow: 5px -5px 5px rgba(0,0,0,0.25), -5px -5px 5px rgba(0,0,0,0.25);
                  z-index: 9999;
	  }

	  a {
		  background-color: transparent;
		  border: none;
		  border-radius: 0;
		  color: #fff;
		  padding: 15px 0;
		  word-break: break-word;
	  }

    svg {
      max-height: 35px;

      path {
        fill: #fff;
      }

      rect {
        stroke: #fff;
      }
    }
  }

  .clubs-navbar-li {
    flex: 1 1 0;
  }

  .clubs-navbar-link {
    height: 100%;
    padding: 5px 10px 15px 10px; 

    img {
      max-width: 100%;
      position: relative;
	  height: 45%;
	  margin-bottom: 10%;
	  top: 10%;
    }

	@media (max-width: $screen-xs-max) {
		img {
			height: 65%;
		}
	}

  }
}

#navbar {
  background-color: #000;

  @media (min-width: $screen-sm-min) { 
    ul.nav.navbar-nav {
      float: none;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: flex-start;
      text-align: center;
      justify-content: center;

      > li {
        flex: 0 auto;
        float: none;
        margin-left: 10px;
        margin-right: 10px;
      }
    }
  }

  li {
    a:hover, a:focus {
      background-color: rgba(255, 255, 255, 0.25);
    }
  }

  li a {
    color: #fff;
    font-family: GraduateRegular;
    font-size: $font-size-large;
    padding-left: 25px;
    padding-right: 25px;
  }

  li.dropdown {
    cursor: pointer;

    .caret {
      color: #fff;
      transform: rotate(90deg);
      margin-left: -4px;
    }

    .dropdown-link {
      display: inline-block;
      padding-right: 21px;
    }

    .dropdown-menu {
      li {
        a:hover, a:focus {
          background-color: rgba(255, 255, 255, 0.25);
        }
        a {
          line-height: 40px;
          border-bottom: 1px solid rgba(255, 255, 255, 0.25);
          letter-spacing: 1px;
          font-size: 15px;
        }
      }
      
      padding: 0;
    }

    &.open {
      .caret {
        transform: none;
      }
    }
  }

  ul.dropdown-menu {
    border-radius: 0;
    margin: 0;
  }

  @media (min-width: $screen-sm-max) {
    .navbar-nav {
      margin: 0 auto;
      display: table;
      table-layout: fixed;
      float: none;
    }
  }

  .nav-pills {
    padding-left: 0;
  }

  @media (max-width: $screen-sm-max) {
    .nav-pills li {
      float: none;
    }
  }
}

.block-container-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: baseline;
  margin-bottom: 40px;
  margin-top: 30px;
}

.btn-carousel {
  font-size: $font-size-giant; 
  font-family: GraduateRegular;
  font-weight: bold;
  line-height: $font-size-giant * 1.5;
  min-width: 200px;
  min-height: 60px;
  box-shadow: 0px 6px 13px 0 rgba(23, 22, 55, 0.65);
  white-space: normal;
  word-wrap: break-word;

  @media (max-width: $screen-sm-max) {
      font-size: $font-size-xl;
      min-height: 50px;
  }

  @media (max-width: 440px) {
    font-size: $font-size-large;
    min-height: 30px;
    padding: 0;
  }
}

.btn-default {
  box-shadow: none;
  text-transform: uppercase;
}

.carousel {
  margin-bottom: 30px;
  .carousel-inner {
    .item {
      background-color: #000;
      -webkit-transform-style: preserve-3d;
      -moz-transform-style: preserve-3d;
      transform-style: preserve-3d;

      img {
        opacity: 0.5;

        @media (max-width: $screen-sm-min) {
          min-height: 300px;
          object-fit: cover;
        }
      }
    }
  }
}

.carousel-caption {
  bottom: initial;
  top: 50%;
  transform: translateY(-50%);

  h1 {
    font-size: $font-size-h1 * 1.5;
  }

  @media (max-width: $screen-sm-max) {
    h1 {
      font-size: $font-size-h1;
    }
  }
}

.carousel-caption-sub {
  font-size: $font-size-large;

  @media (max-width: $screen-sm-max) {
      font-size: $font-size-base;
  }
}

.b {
  font-weight: bold;
}

.bc {
  font-weight: bold;
  text-align: center;
}

.club-action {
  font-size: $font-size-xl;
}

.current-flag {
  font-size: $font-size-large;
}

img.img-responsive-centered {
  margin: auto;
  display: block;
  max-width: 100%;
  height: auto;
}

@media (max-width: $screen-xs-max) {
  img.img-responsive-centered {
    width: 100% !important;
  }
}

.footer-block {
  a {
    color: black;

    &.btn {
      color: white;
    }
  }
  
  &.adventure-reports-block {
    .contents {
      max-height: 700px;
      overflow: auto;
    }
    
    .report-header {
      margin-bottom: 10px;
    }

    .adventure-report-title {
      font-size: 28px;
      line-height: 28px;
      margin-bottom: 12px;
      cursor: pointer;
    }

    .dates-club {
      font-weight: bold;
      display: flex;
      justify-content: space-between;

      > * {
        flex: 1;
      }
    }
  }

  div.contents {
    border: 1px solid black;
    border-radius: 10px;
    padding: 32px 24px;
    box-shadow: 0px 6px 13px 0 rgba(23, 22, 55, 0.2);

    .body {
      h3:first-child {
        margin-top: 0;
      }
    }
  }
}

.home-page-block {
  margin: 0 15px;
  position: relative;
  width: calc(100% - 40px);

  &.footer-block {
    @media (min-width: $screen-sm-min) {
      width: calc(50% - 40px);
    }
  }

  &.club-block {
    @media (min-width: $screen-sm-min) {
      margin: 0 15px;
      text-align: center;
      width: calc(50% - 30px);
    }

    @media (min-width: $screen-md-min) {
      width: calc(33% - 30px);
    }
  }
}

.icon-overlay {
  position: absolute;
  left: 10px;
  top: 10px;
  width: 51px;
  height: 51px;
  border-radius: 100000px;
  background-repeat: no-repeat;
  background-position: center center;
  box-shadow: 0px 3px 7px 0 rgba(0, 0, 0, 0.35);
}

.nav-justified > li {
  @media (max-width: $screen-xs-max) {
    float: left;
  }
}

a.club-link {
  font-weight: bold;
}

.tc {
  text-align: center;
}

h1.page-heading {
  text-align: center;
}

div.weather-conditions-block {
  .contents {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    text-align: center;

    .flag-wrapper img {
      margin: auto;
    }

    .weather-block {
      padding: 15px;
      flex: 1;

      &.flag-wrapper {
        flex: 2 2 auto;
      }
    }
  }
}

.row-spacer {
  margin-bottom: 10px;
  margin-top: 10px;

  margin-left: 10%;
  margin-right: 10%;
  border-bottom: 1px solid #88888888;
  border-right: 100px solid transparent;
  border-left: 100px solid transparent;
}

.upcoming-events-block-body {
  max-height: 700px;
  overflow: auto;
  padding-right: 12px;

  .event-entry {
    padding: 8px;

    .date {
      font-size: 26px;
      font-weight: bold;
      line-height: 28px;
    }

    .name-container {
      font-size: 28px;
      line-height: 28px;
      margin-bottom: 12px;
      margin-left: 20px;
      margin-right: -20px;

      a {
        margin-bottom: 20px;
        text-align: center;
        cursor: pointer;
      }
    }

    .disc-container { 
      .desc {
        a.event-modal-button {
          text-align: right;
          float: right;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    } 
  }
}

.modal.upcoming-events-detail {
  .modal-body {
    h1 {
      font-size: $font-size-h1 / 1.5;
    }
    h2 {
      font-size: $font-size-h2 / 1.5;
    }
    h3 {
      font-size: $font-size-h3 / 1.5;
    }
    h4 {
      font-size: $font-size-h4 / 1.5;
    }

    p:empty {
      display: none;
    }
  }
}

.embed-responsive-1by1 {
  padding-bottom: 100%;
}

.embed {
  &.center {
    margin: auto;
  }

  &.right {
    text-align: right; 
  }
}

.embed-width-1by4 {
  width: 25%;
  &.right { margin-left: 75%; }
}

.embed-width-1by3 {
  width: 33.3333%;
  &.right { margin-left: 66.6667%; }
}

.embed-width-1by2 {
  width: 50%;
  &.right { margin-left: 50%; }
}

.embed-width-2by3 {
  width: 66.6667%;
  &.right { margin-left: 33.3333%; }
}

.embed-width-3by4 {
  width: 75%;
  &.right { margin-left: 25%; }
}

.embed-width-full {
  width: 100%;
}

/* vim: set expandtab ts=2 sw=2: */
